import {
    AccountReserves,
    BindBankAccountModel,
    BindSpecialBankAccountModel,
    CheckUniqueBankAccountRequest,
    CheckUniqueBankAccountResponse,
    GetFilePaymentOrderPayload,
    GetFilePaymentOrderResponse,
    GetNominalAccountsParams,
    GetPaymentOrderParams,
    GetTransactionParams,
    IBankAccountsUser,
    ICursorPaginationResponse,
    IMoneyAccountsSummary,
    IMoneyReserveTransferSauToBankAccount,
    IMoneyReserveTransferTransaction,
    IRequest,
    MoneyAccountByNominal,
    MoneyAccountFull,
    MoneyAccountType,
    NominalAccountModel,
    PaymentOrder,
    PostBankAccountDetailsModel,
    ReserveDetails,
    ReserveReleasePostData,
    ReserveReleaseResponse,
    ReserveTransferPostData,
    ReserveTransferResponse,
    TransactionPostData,
    TransactionType,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem, ResponseItems } from '../../types/types'
import { path } from '../../utils'

export async function getMoneyAccounts(
    uuid: string,
): Promise<IRequest<ResponseItems<MoneyAccountType>>> {
    return requestDecorator.call(
        this,
        path(this.paths.MoneyAccountsByAccountId, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getMoneyAccountsBalance(uuid: string): Promise<IRequest<MoneyAccountType>> {
    return requestDecorator.call(
        this,
        path(this.paths.MoneyAccountsBalanceByAccountIdV2, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getReservesByAccount(
    accountId: string,
): Promise<IRequest<{ item: AccountReserves }>> {
    return requestDecorator.call(
        this,
        path(this.paths.MoneyAccountReserves, accountId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function sendDebit<T>(data: TransactionPostData): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.MoneyTransactionDebit,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function moneyReserveTransfer(
    data: ReserveTransferPostData,
): Promise<IRequest<ResponseItem<ReserveTransferResponse>>> {
    return requestDecorator.call(
        this,
        this.paths.MoneyReserveTransfer,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function moneyReserveReleaseTransferSauToBank(
    data: ReserveReleasePostData,
): Promise<IRequest<ResponseItem<ReserveReleaseResponse>>> {
    return requestDecorator.call(
        this,
        this.paths.MoneyReserveReleaseTransferSauToBank,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function sendCredit(payload: TransactionPostData): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.MoneyTransactionCredit,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function getClientUserBankAccounts(
    userId: string,
): Promise<IRequest<ResponseItems<IBankAccountsUser>>> {
    return requestDecorator.call(
        this,
        path(this.paths.MoneyUserBankAccountsByUserId, userId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export interface IAccountsResponse extends IMoneyAccountsSummary {
    accounts: MoneyAccountFull[]
}
export async function getAccounts(query?: {
    blocked?: boolean
}): Promise<IRequest<ResponseItem<IAccountsResponse>>> {
    return requestDecorator.call(
        this,
        this.paths.MoneyAccounts,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params: { ...query } },
    )
}

export async function getTransactionById(
    transactionId: string,
): Promise<IRequest<ResponseItem<TransactionType>>> {
    return requestDecorator.call(
        this,
        path(this.paths.MoneyTransactionByTransactionId, transactionId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getPaymentOrders(
    params?: GetPaymentOrderParams,
): Promise<IRequest<ICursorPaginationResponse<PaymentOrder>>> {
    const paramsWithOrder = { order: 'desc(id)', ...params }
    return requestDecorator.call(
        this,
        this.paths.MoneyPaymentOrders,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params: paramsWithOrder },
    )
}

export async function createPaymentOrder(transaction_id: string): Promise<IRequest<string>> {
    return requestDecorator.call(
        this,
        this.paths.MoneyPaymentOrdersGenerate,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { transaction_id } },
    )
}

export async function getFilePaymentOrder(
    payload: GetFilePaymentOrderPayload,
): Promise<IRequest<ResponseItem<GetFilePaymentOrderResponse>>> {
    return requestDecorator.call(
        this,
        this.paths.FilePaymentOrder,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function postExecutedPaymentOrder(
    data: IMoneyReserveTransferSauToBankAccount,
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.MoneyReserveTransferConfirmSauToBankAccount,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function moneyReservePaymentOrderCancelSauToBankAccount(
    data: IMoneyReserveTransferSauToBankAccount,
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.MoneyReservePaymentOrderCancelSauToBankAccount,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function moneyReserveTransferCancelSauToBankAccount(
    data: IMoneyReserveTransferTransaction,
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.MoneyReserveTransferCancelSauToBankAccount,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getTransactions(
    params: GetTransactionParams,
): Promise<IRequest<ICursorPaginationResponse<TransactionType>>> {
    return requestDecorator.call(
        this,
        this.paths.MoneyTransactions,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function getReserves(
    params,
): Promise<IRequest<ICursorPaginationResponse<ReserveDetails>>> {
    return requestDecorator.call(
        this,
        this.paths.MoneyAccountReservesPaging,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function getNominalAccounts(
    params: GetNominalAccountsParams,
): Promise<IRequest<ICursorPaginationResponse<NominalAccountModel>>> {
    return requestDecorator.call(
        this,
        this.paths.MoneyNominalAccounts,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function postNominalAccount(
    data: PostBankAccountDetailsModel,
): Promise<IRequest<NominalAccountModel>> {
    return requestDecorator.call(
        this,
        this.paths.MoneyNominalAccounts,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
export async function checkUniqueNominalAccount(
    data: CheckUniqueBankAccountRequest,
): Promise<IRequest<CheckUniqueBankAccountResponse>> {
    return requestDecorator.call(
        this,
        this.paths.BankAccountsCheckUnique,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getNominalAccountById(id: string): Promise<IRequest<NominalAccountModel>> {
    return requestDecorator.call(
        this,
        path(this.paths.MoneyNominalAccountById, id),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function bindNominalAccountById(data: BindBankAccountModel): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.MoneyNominalAccountBind,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getRelatedAccountsByNominalAccount(
    nominalAccountUid: string,
): Promise<IRequest<ICursorPaginationResponse<MoneyAccountByNominal>>> {
    return requestDecorator.call(
        this,
        path(this.paths.MoneyRelatedAccountsByNominalAccount, nominalAccountUid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params: { limit: 100 } },
    )
}

export async function getAccountByTypeServie(
    accountType: string,
): Promise<IRequest<NominalAccountModel>> {
    return requestDecorator.call(
        this,
        path(this.paths.GetAccountByType, accountType),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function patchMoneyAccountBindByUuidService(
    data: BindSpecialBankAccountModel,
): Promise<IRequest<NominalAccountModel>> {
    return requestDecorator.call(
        this,
        this.paths.MoneyAccountBindByUuid,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function transferInitSauByUuidToBankAccount(
    data: ReserveReleasePostData,
): Promise<IRequest<ResponseItem<ReserveReleaseResponse>>> {
    return requestDecorator.call(
        this,
        this.paths.TransferInitSauByUuidToBankAccount,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
